






















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import {
    ISenderSmsGetAll, SenderSmsStatusEnum,
} from '@/api/consoleApi/dto/senders.dto'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsSenderCard',
    components: {
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private sender!: ISenderSmsGetAll

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean

    get badgeStatusAspect () {
        if (!this.sender?.status) return ''
        switch (this.sender?.status || null) {
            case SenderSmsStatusEnum.Approval:
                return 'info'
            case SenderSmsStatusEnum.Disabled:
                return 'alert'
            case SenderSmsStatusEnum.Enabled:
                return 'success'
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.sender?.status) return ''
        switch (this.sender?.status || null) {
            case SenderSmsStatusEnum.Approval:
                return 'clock'
            case SenderSmsStatusEnum.Disabled:
                return 'error'
            case SenderSmsStatusEnum.Enabled:
                return 'double-check'
        }
    }
}
