
























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getSendersSms, deleteSenderSms } from '@/api/consoleApi/senders/sms'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
import VsConfirmSmsSender from '@/modules/campaigns/components/VsConfirmSmsSender/Index.vue'
import VsCreateSmsSender from '@/modules/campaigns/components/VsCreateSmsSender/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsCreateApikeyModal from '../components/VsCreateApikeyModal/Index.vue'
import VsSenderCard from '../components/VsSenderCard/Index.vue'
import VsSenderDetailsDrawer from '../components/VsSenderDetailsDrawer/Index.vue'
import { ISenderSmsGetAll } from '@/api/consoleApi/dto/senders.dto'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'

@Component({
    name: 'SendersSms',
    components: {
        VsLoader,
        VsConfirm,
        VsCreateApikeyModal,
        VsSenderCard,
        VsSenderDetailsDrawer,
        VsConfirmSmsSender,
        VsCreateSmsSender,
        VsSectionHeader,
    },
})
export default class extends Vue {
    private loading = false
    senders = []
    totalItems = 0

    private filters = {
        status: '',
        search: '',
    }

    private pagination: any = {
        currentPage: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    $refs: any

    get user () {
        return UserModule.user
    }

    get senderStatus () {
        return [
            {
                label: this.$t('senders.index.status.toBeConfirmed'),
                value: 'notConfirmed',
            },
            {
                label: this.$t('senders.index.status.enabled'),
                value: 'enabled',
            },
            {
                label: this.$t('senders.index.status.disabled'),
                value: 'disabled',
            },
            {
                label: this.$t('senders.index.status.approval'),
                value: 'approval',
            },
        ]
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getSenders () {
        try {
            this.loading = true
            const resp = await getSendersSms(this.buildParams())
            this.senders = resp.data.data
            this.totalItems = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.senders = []
            this.totalItems = 0
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.status === 'notConfirmed' ? 'confirmed:0' : '',
            this.filters.status === 'enabled' ? 'confirmed:1;status:Enabled' : '',
            this.filters.status === 'disabled' ? 'confirmed:1;status:Disabled' : '',
            this.filters.status === 'approval' ? 'confirmed:1;status:Approval' : '',
            this.filters.search.trim() !== '' ? `alias:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private async openDetail (sender: ISenderSmsGetAll) {
        this.$refs.vsSenderDetailsDrawer.openDrawer(sender)
    }

    private async openConfirmSender (sender: ISenderSmsGetAll) {
        this.$refs.vsConfirmSmsSender.openModal(sender)
    }

    private async deleteSender (id: string) {
        try {
            await this.$refs.vsConfirmDeleteSenderSms.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteSenderSms(id)
            await this.getSenders()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Mittente eliminato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del mittente',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }
}
