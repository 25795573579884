










































































































import { Component, Vue } from 'vue-property-decorator'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { ISenderSmsGetAll } from '@/api/consoleApi/dto/senders.dto'

@Component({
    name: 'VsSenderDetailsDrawer',
    components: {
        VsFormSection,
        VsSectionHeader,
    },
})
export default class extends Vue {
    private open = false
    public loading = false
    private sender: any = null
    $refs: any

    public async openDrawer (sender: ISenderSmsGetAll) {
        this.loading = true
        this.open = true
        this.sender = sender
        this.loading = false
    }

    public closeDrawer () {
        this.open = false
    }
}
